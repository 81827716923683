@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-black;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.outlined-text-none {
  -webkit-text-stroke: none;
  text-shadow: none;
}

.outlined-text-1 {
  font-style: italic;
  -webkit-text-stroke: 1px black, 7px #000, 8px #000; /* Applies multiple black outlines */
  text-shadow: 
    1px 1px 0 #000,
   -1px 1px 0 #000,
    1px -1px 0 #000,
   -1px -1px 0 #000,
    1px 0 0 #000,
    -1px 0 0 #000,
    0 1px 0 #000,
    0 -1px 0 #000; /* Alternative outline for browsers without text-stroke support */
}

.outlined-text-2 {
  font-style: italic;
  -webkit-text-stroke: 2px black, 7px #000, 8px #000; /* Applies multiple black outlines */
  text-shadow: 
    2px 2px 0 #000,
   -2px 2px 0 #000,
    2px -2px 0 #000,
   -2px -2px 0 #000,
    2px 0 0 #000,
   -2px 0 0 #000,
    0 2px 0 #000,
    0 -2px 0 #000; /* Alternative outline for browsers without text-stroke support */
}

.outlined-text-3 {
  font-style: italic;
  -webkit-text-stroke: 3px black, 7px #000, 8px #000; /* Applies multiple black outlines */
  text-shadow: 
    3px 3px 0 #000,
   -3px 3px 0 #000,
    3px -3px 0 #000,
   -3px -3px 0 #000,
    3px 0 0 #000,
   -3px 0 0 #000,
    0 3px 0 #000,
    0 -3px 0 #000; /* Alternative outline for browsers without text-stroke support */
}

.outlined-text-4 {
  font-style: italic;
  -webkit-text-stroke: 4px black, 7px #000, 8px #000; /* Applies multiple black outlines */
  text-shadow: 
    4px 4px 0 #000,
   -4px 4px 0 #000,
    4px -4px 0 #000,
   -4px -4px 0 #000,
    4px 0 0 #000,
   -4px 0 0 #000,
    0 4px 0 #000,
    0 -4px 0 #000; /* Alternative outline for browsers without text-stroke support */
}

.outlined-text-5 {
  font-style: italic;
  -webkit-text-stroke: 5px black, 7px #000, 8px #000; /* Applies multiple black outlines */
  text-shadow: 
    5px 5px 0 #000,
   -5px 5px 0 #000,
    5px -5px 0 #000,
   -5px -5px 0 #000,
    5px 0 0 #000,
   -5px 0 0 #000,
    0 5px 0 #000,
    0 -5px 0 #000; /* Alternative outline for browsers without text-stroke support */
}

.outlined-text-6 {
  font-style: italic;
  -webkit-text-stroke: 6px black, 7px #000, 8px #000; /* Applies multiple black outlines */
  text-shadow: 
    6px 6px 0 #000,
   -6px 6px 0 #000,
    6px -6px 0 #000,
   -6px -6px 0 #000,
    6px 0 0 #000,
    -6px 0 0 #000,
    0 6px 0 #000,
    0 -6px 0 #000; /* Alternative outline for browsers without text-stroke support */
}
